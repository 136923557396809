// import './wdyr'

// import libraries
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { Provider } from "mobx-react";

// import components
import App from "kilowatt-front/components/App.jsx";

// import routes
import ProtectedRoutes from "kilowatt-front/routes/ProtectedRoutes.jsx";
import LoginRoutes from "kilowatt-front/routes/Auth/LoginRoutes";

// import services
import AuthService from "kilowatt-front/services/AuthService.js";

// import stores
import { CurrentUserStore } from "kilowatt-front/state/domain/stores/CurrentUserStore.js";
import customerStore from "../../../kilowatt.app/state/access/stores/CustomerStore";
import AppStore from "kilowatt-front/state/domain/stores/AppStore.js";

// ----END OF IMPORTS-------------------------------------------------------

// initialize mobx stores
const currentUserStore = new CurrentUserStore();
const appStore = new AppStore();

function renderRoutes() {
  const customHistory = createBrowserHistory();
  const redirectUrl = `${window.location.origin}/app/auth/callback`;

  // This log statement is here for when the white screen appears in the auth0 login page
  // sometimes pushing new code resolves the issue so Im just trying to chase it when I can.
  // Logging on the AuthService.js did not appear with the white screen.
  console.log("Kilowatt")
  // console.log(import.meta.env.VITE_AUTH0_CLIENT_ID, import.meta.env.VITE_AUTH0_DOMAIN, redirectUrl, "/app", window.showSignup, window.emailVerified, currentUserStore)

  const auth = new AuthService(
    import.meta.env.VITE_AUTH0_CLIENT_ID,
    import.meta.env.VITE_AUTH0_DOMAIN,
    redirectUrl,
    "/app",
    window.showSignup,
    window.emailVerified,
    currentUserStore,
    () => {
      // This function is called after successful authentication
      const redirectPath = sessionStorage.getItem('original_location') || `/${appName}/map`;
      sessionStorage.removeItem('original_location');
      customHistory.push(redirectPath);
    }
  );

  const stores = {
    auth,
    customerStore,
    currentUserStore,
    appStore
  }

  const appName = window.appName;

  return (
    <Provider {...stores}>
      <Router history={customHistory}>
        <StrictMode>
          <Routes>
            <Route path="/app/*" element={<App />}>
              <Route
                index
                element={<Navigate to={`${appName}/map` || "redirect"} replace />}
              />
              <Route path="auth/*" element={<LoginRoutes />} />
              <Route path="*" element={<ProtectedRoutes />} />
            </Route>
          </Routes>
        </StrictMode>
      </Router>
    </Provider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(renderRoutes());